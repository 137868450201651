<template>
  <div class="page sonuc">
    <div class="formcontainer">
      <div class="formbox">
        <!-- <div class="hub_header"> -->
        <h2>Başvuru Durumu</h2>

        <div class="formpart">
          <div class="formfull">
            <div class="hubbox">
              <div v-if="CONFIRMED">
                <!-- <h3>
                  Hesap yöneticilerimiz {{ application.appNo }} no'lu
                  başvurunuzu sonuçlandırdı.
                </h3> -->

                <div class="header">
                  <h3 style="color: #229f37">Başvurunuz Onaylanmıştır.</h3>
                </div>

                <p>
                   Hizmet sağlayıcı hesabınıza ait bilgiler kısa bir süre içerisinde e-posta adresininize gönderilecektir.
                </p>

                <div class="half"></div>
                <div class="btn half fl">
                  <router-link to="/hizmet-saglayici/basvuru/sonuc-detay"
                    >Detayları İncele</router-link
                  >
                </div>
              </div>

              <div v-if="SUBMITTED">
                <h3>{{ application.appNo }} no'lu başvurunuz alınmıştır.</h3>

                <div class="header">
                  <h4 class="yello_d">
                    Hesap yöneticilerimiz başvurunuzu inceleyecektir.
                  </h4>
                </div>

                <p>
                  Başvurunuzun değerlendirme sürecine ilişkin bildirimler
                  telefon ve e-posta yoluyla yapılacaktır.
                </p>

                <div class="half"></div>
                <div class="btn half fl">
                  <router-link to="/hizmet-saglayici/basvuru/sonuc-detay"
                    >Detayları İncele</router-link
                  >
                </div>
              </div>

              <div v-if="EDITED">
                <h4>Sayın {{ application.contact.name }}</h4>
                <h3>
                  {{ application.appNo }} no'lu başvuru güncellemeniz
                  alınmıştır.
                </h3>

                <div class="header">
                  <h4 class="yello_d">
                    Hesap yöneticilerimiz başvurunuzu tekrar inceleyecektir.
                  </h4>
                </div>

                <div>
                  <p>
                    Başvurunuzun değerlendirme sürecine ilişkin bildirimler
                    telefon ve e-posta yoluyla yapılacaktır.
                    <br /><b>Başvuru numaranızın</b> yer aldığı size gönderilmiş
                    e-posta'yı <b>lütfen saklayınız</b>.
                  </p>
                </div>

                <div class="half"></div>
                <div class="btn half fl">
                  <router-link to="/hizmet-saglayici/basvuru/sonuc-detay"
                    >Detayları İncele</router-link
                  >
                </div>
              </div>

              <div v-if="REJECTED">
                <h3>
                  {{ application.appNo }} no'lu başvurunuz incelenmiş ve
                  tarafınızca güncelleme yapılması gerektiği tespit edilmiştir.
                </h3>

                <div class="header">
                  <img src="@/assets/img/ico_exclamination.svg" alt />
                  <h4 style="color: #ce2f27">İşlem yapmanız bekleniyor.</h4>
                </div>

                <p>
                  Başvurunuza ilişkin detayları inceleyip gerekli değişiklikleri
                  yapmanız halinde yeniden kontrol edilerek tarafınıza bildirim
                  yapılacaktır.
                </p>

                <div class="half"></div>
                <div class="btn half fl">
                  <router-link to="/hizmet-saglayici/basvuru/bilgi-girisi"
                    >Belgeleri Düzenle</router-link
                  >
                </div>
              </div>
            </div>

            <div v-if="false">
              <!-- Paket ile ilgili içeriği gizleyen DIV  -->

              <div v-if="CONFIRMED" class="stickminmargin">
                <!-- Paket satın almamış kullanıcıya görünecek
                <a @click="NEXT">
                  <div class="promobox hover"><img class="normal" src="@/assets/img/icons/promobox_03.svg" alt /><img class="mobile" src="@/assets/img/icons/promobox_03m.svg" alt /></div>
                </a>-->

                <!-- <div class="promobox">
                  <img
                    class="normal"
                    src="@/assets/img/icons/promobox_01.svg"
                    alt
                  />
                  <img
                    class="mobile"
                    src="@/assets/img/icons/promobox_01m.svg"
                    alt
                  />
                </div> -->

                <!-- <paket
                  duzlem="dikey"
                  hasbutton
                  :onerilen_id="suggested"
                  @onSecim="SELECT"
                ></paket> -->
              </div>

              <!-- <div v-if="application.paymentStatus == 'CONFIRMED'">
                Paket bilgisi:
                <paket
                  duzlem="yatay"
                  :paket_ids="[application.paketId || 1]"
                  :paket_listesi="packets"
                ></paket>
              </div> -->
            </div>

            <!-- <div class="formfooter">
              <div class="text">
                Talep, öneri veya şikâyetiniz olması halinde
                <b>138 İleti Yönetim Sistemi Çağrı Merkezi</b>'ni arayarak bizimle iletişim
                kurabileceksiniz.
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "HSBasvuruSonuc",

  computed: {
    ...mapState("applicant", ["application"]),
    ...mapState("purchase", ["packets"]),
    ...mapGetters("purchase", ["suggested"]),

    SUBMITTED() {
      return (
        this.application.step === "FINAL_SUBMITTED_TO_AGENT" ||
        this.application.step === "INITIAL_SUBMITTED_TO_AGENT"
      );
    },

    REJECTED() {
      return this.application.step === "FINAL_AGENT_REJECTED";
    },

    CONFIRMED() {
      return this.application.step === "FINAL_AGENT_CONFIRMED";
    },

    EDITED() {
      return this.application.step === "FINAL_DOCUMENTS_EDITED";
    },
  },

  methods: {
    ...mapMutations("purchase", ["SET_PAKET_ID"]),

    SELECT(paket_id) {
      this.SET_PAKET_ID(paket_id);
      let loader = this.$loading.show();
      setTimeout(() => {
        loader.hide();

        if (paket_id == 1) {
          return this.$router.push("/hizmet-saglayici/odeme/onay-ucretsiz");
        }
        if (paket_id == 2) {
          return this.$router.push("/hizmet-saglayici/odeme/onay-baslangic");
        }
        if (paket_id == 7) {
          return this.$router.push("/hizmet-saglayici/odeme/onay-enterprise");
        }

        this.$router.push("/hizmet-saglayici/odeme/odeme");
      }, Math.random() * 200 + 200);
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: flex-end;
  margin: 2vh 0;
}

.hub_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.hubbox {
  background: #fdfbfa;
  padding: 4vh;
  border-radius: 0.5vw;
  margin: 2vh 0;
  position: relative;
}

.hubbox .desc {
  margin: 2vh 0;
}

.basvuruno .basvurunobox {
  background: #d8d8d8;
  padding: 1vw;
}

.basvuruno {
  width: unset;
}
.basvuruno .basvurunobox {
  margin-right: 0;
}
</style>
